.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.app-button {
  width: fit-content;
  padding: 5px;
  border: "2px solid pink";
}
.app-button:hover {
  border: "2px solid red";
}

.body-content {
  height: max-content;
  width: 100%;
}
